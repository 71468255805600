


































































































































































import {MultipleItem, SingleItem} from "@/handlers/interfaces/ContentUI"
import {Component, Mixins, Watch} from 'vue-property-decorator'
import PaginationMixin from "@/mixins/PaginationMixin"
import SynchroService from "@/services/SynchroService"
import Handler from "@/handlers/Handler"
import Synchro, {SynchroStatus} from "@/models/Synchro"
import LogService from "@/services/LogService";
import Log from "@/models/Log";
import LangModule from "@/store/LangModule";
import {getModule} from "vuex-module-decorators";

@Component({
    computed: {
        SynchroStatus() {
            return SynchroStatus
        }
    }
})
export default class SynchroView extends Mixins(PaginationMixin) {

    get lang() { return getModule(LangModule).lang }
    loading: boolean = false
    synchro: SingleItem<Synchro> = { item: new Synchro() }
    logs: MultipleItem<Log> = { items: [], totalItems: 0 }
    log: Log = new Log()
    dialog: boolean = false

    headers = [
        {align: "center", text: this.lang.date, value: "createdAt", width: "250px"},
        {align: "center", text: this.lang.status, value: "type", width: "250px"},
        {align: "center", text: this.lang.message, value: "subject", width: "auto"},
        {align: "center", text: "Contexto", value: "log", width: "400px"},
    ]
    tab: number = 0

    created() { this.refresh() }

    async refresh() {
        await Handler.getItem(this, this.synchro, () =>
            SynchroService.getSynchro(Number(this.$route.params.id))
        )

        if (this.synchro.item.id) {
            await Handler.getItems(this, this.logs, () =>
                LogService.findLogs(this.page - 1, this.size, this.search, this.synchro.item.id!)
            )
            this.setPageCount(this.logs.totalItems!!)
        }
    }

    @Watch("page")
    onPageChanged() { this.refresh() }

    newQuery() {
        this.page = 1
        this.refresh()
    }

    openLog(log: Log) {
        this.log = log
        this.dialog = true
    }

}
